import React from 'react';
import {useTranslation} from "react-i18next";
import Languages from "../components/form/Languages";
import {useNavigate, useParams} from "react-router-dom";

const Rates = () => {
    const {hash} = useParams();
    const { t } = useTranslation([hash, 'rates']);
    const navigate = useNavigate();

    return (
        <div>
            <div className="rates">
                <h3 className="login__subtitle login__subtitle--in">
                    {t('rates.title', )}
                </h3>

                <div className="login__languages jcc languages mb-3">
                    <Languages hideExit={true} centered={true}/>
                </div>

                <div>
                    <div dangerouslySetInnerHTML={{__html: t('rates.text')}}>
                    </div>

                    <div className="login__inner jcc mb-1">
                        <button onClick={() => navigate('/')}
                                className="login__box login__box--answer">{t('rates.btn_back')}
                        </button>
                    </div>

                    <span>
                        {t('rates.footer_ps')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Rates;
